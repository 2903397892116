@import url(https://unpkg.com/leaflet@1.5.1/dist/leaflet.css);


.list-group-title {
  border-bottom: 2px solid;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.points-list-group{
    max-height: 300px;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
}


.list-group-item.active {
  border-color: #212529;
  color: #212529;
}

